import React, { SVGProps, useId } from 'react';

export const ExclamationPoint = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();

  return (
    <svg viewBox="0 0 16 16" {...props}>
      <g clip-path={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8.895 4.50001C8.895 4.00295 8.49206 3.60001 7.995 3.60001C7.49794 3.60001 7.095 4.00295 7.095 4.50001V8.00001C7.095 8.49706 7.49794 8.90001 7.995 8.90001C8.49206 8.90001 8.895 8.49706 8.895 8.00001V4.50001ZM7.995 10.6C7.49794 10.6 7.095 11.003 7.095 11.5C7.095 11.9971 7.49794 12.4 7.995 12.4H8.005C8.50206 12.4 8.905 11.9971 8.905 11.5C8.905 11.003 8.50206 10.6 8.005 10.6H7.995Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9082_53700">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
